import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Assumption } from "../API";
import { ERROR_ID } from "../constants";
import { API } from "aws-amplify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Yearly Saving VS Retire Age",
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "Retire Age",
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Yearly Savings",
      },
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
type Dataset = {
  label: string;
  data: bigint[];
};

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => 500),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => 800),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export function BarChart({
  assumptions,
  onError,
}: {
  assumptions: Assumption[];
  onError?: any;
}) {
  const [barChartData, setBarChartData] = useState<any>(data);

  useEffect(() => {
    CalculateBarChart(assumptions);
    return () => {
      // You can perform cleanup here if needed.
      // For example, cancelling network requests, clearing intervals, etc.
    };
  }, [assumptions]);
  return <Bar options={options} data={barChartData} />;

  async function CalculateBarChart(assumptions: Assumption[]) {
    const apiName = "FaithRetireRestAPI";
    const path = "/chart/bar";
    const body = JSON.stringify(assumptions);
    const myInit = {
      body: assumptions,
    };

    try {
      const response = await API.post(apiName, path, myInit);
      setBarChartData(response);
    } catch (error) {
      if (error instanceof Error) {
        onError(error.message);
      } else {
        onError(ERROR_ID.UNABLE_TO_CALCULATE);
      }
    }
  }
}

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAssumption = /* GraphQL */ `query GetAssumption($id: ID!) {
  getAssumption(id: $id) {
    id
    name
    birthday
    email
    inflation
    returnOnInvestiment
    returnOnInvestimentAfterRetire
    superAmount
    superRecievingAge
    monthlySaving
    retireAge
    monthlyRetireExpense
    currentSavings
    lifeExpectancy
    incomeStreams {
      items {
        amount
        assumptionId
        endAge
        id
        name
        startAge
      }
    }
    expenseStreams {
      items {
        amount
        assumptionId
        endAge
        id
        name
        startAge
      }
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssumptionQueryVariables,
  APITypes.GetAssumptionQuery
>;
export const listAssumptions = /* GraphQL */ `query ListAssumptions(
  $filter: ModelAssumptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listAssumptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      incomeStreams {
        items {
          amount
          assumptionId
          endAge
          id
          name
          startAge
        }
      }
      expenseStreams {
        items {
          amount
          assumptionId
          endAge
          id
          name
          startAge
        }
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssumptionsQueryVariables,
  APITypes.ListAssumptionsQuery
>;
export const assumptionByEmail = /* GraphQL */ `query AssumptionByEmail(
  $email: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAssumptionFilterInput
  $limit: Int
  $nextToken: String
) {
  assumptionByEmail(
    email: $email
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      
      incomeStreams {
        items {
          amount
          assumptionId
          endAge
          id
          name
          startAge
        }
      }
      expenseStreams {
        items {
          amount
          assumptionId
          endAge
          id
          name
          startAge
        }
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AssumptionByEmailQueryVariables,
  APITypes.AssumptionByEmailQuery
>;
export const getIncomeStream = /* GraphQL */ `query GetIncomeStream($id: ID!) {
  getIncomeStream(id: $id) {
    id
    name
    amount
    startAge
    endAge
    assumptionId
    assumption {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIncomeStreamQueryVariables,
  APITypes.GetIncomeStreamQuery
>;
export const listIncomeStreams = /* GraphQL */ `query ListIncomeStreams(
  $filter: ModelIncomeStreamFilterInput
  $limit: Int
  $nextToken: String
) {
  listIncomeStreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      amount
      startAge
      endAge
      assumptionId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIncomeStreamsQueryVariables,
  APITypes.ListIncomeStreamsQuery
>;
export const incomeStreamsByAssumptionIdAndStartAge = /* GraphQL */ `query IncomeStreamsByAssumptionIdAndStartAge(
  $assumptionId: ID!
  $startAge: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIncomeStreamFilterInput
  $limit: Int
  $nextToken: String
) {
  incomeStreamsByAssumptionIdAndStartAge(
    assumptionId: $assumptionId
    startAge: $startAge
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      amount
      startAge
      endAge
      assumptionId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IncomeStreamsByAssumptionIdAndStartAgeQueryVariables,
  APITypes.IncomeStreamsByAssumptionIdAndStartAgeQuery
>;
export const getExpenseStream = /* GraphQL */ `query GetExpenseStream($id: ID!) {
  getExpenseStream(id: $id) {
    id
    name
    amount
    startAge
    endAge
    assumptionId
    assumption {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExpenseStreamQueryVariables,
  APITypes.GetExpenseStreamQuery
>;
export const listExpenseStreams = /* GraphQL */ `query ListExpenseStreams(
  $filter: ModelExpenseStreamFilterInput
  $limit: Int
  $nextToken: String
) {
  listExpenseStreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      amount
      startAge
      endAge
      assumptionId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExpenseStreamsQueryVariables,
  APITypes.ListExpenseStreamsQuery
>;
export const expenseStreamsByAssumptionIdAndStartAge = /* GraphQL */ `query ExpenseStreamsByAssumptionIdAndStartAge(
  $assumptionId: ID!
  $startAge: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelExpenseStreamFilterInput
  $limit: Int
  $nextToken: String
) {
  expenseStreamsByAssumptionIdAndStartAge(
    assumptionId: $assumptionId
    startAge: $startAge
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      amount
      startAge
      endAge
      assumptionId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExpenseStreamsByAssumptionIdAndStartAgeQueryVariables,
  APITypes.ExpenseStreamsByAssumptionIdAndStartAgeQuery
>;
export const getUserInfo = /* GraphQL */ `query GetUserInfo($id: ID!) {
  getUserInfo(id: $id) {
    id
    email
    firstName
    lastName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserInfoQueryVariables,
  APITypes.GetUserInfoQuery
>;
export const listUserInfos = /* GraphQL */ `query ListUserInfos(
  $filter: ModelUserInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserInfosQueryVariables,
  APITypes.ListUserInfosQuery
>;
export const userByEmail = /* GraphQL */ `query UserByEmail(
  $email: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  userByEmail(
    email: $email
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      firstName
      lastName
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByEmailQueryVariables,
  APITypes.UserByEmailQuery
>;

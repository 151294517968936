import { Alert, TabItem, Tabs, View, withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Assumption } from "../API";
import DataTable from "../components/DataTable";
import { ERROR_ID } from "../constants";
import { GetAssumptionsByEmail } from "../lib/AssumptionLib";
import { AssumptionReport } from "../myModels/AssumptionReport";
import { assumptionByEmail } from "../graphql/queries";
import { components } from "../components/SignInComponents";
import NavBarHeader from "../components/NavBarHeader";
export default withAuthenticator(Report, { components: components });

function Report({ signOut, user }: { signOut: any; user: any }) {
  async function GetAssumptionsByEmail(email: string) {
    const result = await API.graphql({
      query: assumptionByEmail,
      variables: { email: email },
    });
    const assumptions =
      "data" in result ? result.data.assumptionByEmail.items : undefined;
    setAssumptions(assumptions);
  }
  const [assumptions, setAssumptions] = useState<Assumption[]>();
  const [alertMessage, setAlertMessage] = useState("");
  const [assumptionReports, setAssumptionReports] =
    useState<AssumptionReport[]>();

  useEffect(() => {
    GetAssumptionsByEmail(user.attributes.email);
    return () => {};
  }, [user]);

  useEffect(() => {
    assumptions && GetAssumptionReports(assumptions);
    return () => {};
  }, [assumptions]);

  function GetTabItems(): import("react").ReactNode {
    return (
      assumptionReports &&
      assumptions?.map((assumption) => {
        const assumptionReport = assumptionReports.findLast(
          (r) => r.AssumptionId == assumption.id
        );
        return (
          assumptionReport && (
            <TabItem title={assumption.name}>
              <View>
                <DataTable assumptionReport={assumptionReport}></DataTable>
              </View>
            </TabItem>
          )
        );
      })
    );
  }

  return (
    <>
      <NavBarHeader signOut={signOut} user={user}></NavBarHeader>
      {alertMessage && (
        <Alert variation="error" heading="Error" isDismissible={true}>
          {" "}
          {alertMessage}
        </Alert>
      )}
      <Tabs justifyContent="flex-start">{GetTabItems()}</Tabs>
    </>
  );

  async function GetAssumptionReports(assumptions: Assumption[]) {
    const apiName = "FaithRetireRestAPI";
    const path = "/chart/line/report";
    const myInit = {
      body: assumptions,
    };
    try {
      const assumptionReports = await API.post(apiName, path, myInit);
      console.log(assumptionReports);
      setAssumptionReports(assumptionReports);
    } catch (error) {
      if (error instanceof Error) {
        setAlertMessage(error.message);
      } else {
        setAlertMessage(ERROR_ID.UNABLE_TO_CALCULATE);
      }
    }
  }
}

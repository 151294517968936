export function trimFields(fields) {
  // Example function to trim all string inputs
  const updatedFields = {};
  Object.keys(fields).forEach((key) => {
    const value = fields[key];
    if (typeof fields[key] === "string") {
      updatedFields[key] = fields[key].trim();
    } else {
      updatedFields[key] = fields[key];
    }
  });
  return updatedFields;
}

export function createArrayFrom1ToN(n) {
  return Array.from({ length: n }, (_, index) => index + 1);
}

export function RefreshPage() {
  window.location.reload();
}
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { React } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import awsconfig from "./aws-exports";
import LandingPage from "./landingPageComponents/LandingPage";
import ErrorPage from "./pages/NoPage";
import Dashboard from "./routes/Dashboard";
import Report from "./routes/Report";

export default function App() {
  Amplify.configure(awsconfig);
  const router = createBrowserRouter([
    {
      path: "/dashboard",
      element: <Dashboard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/report",
      element: <Report />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <ErrorPage />,
    },
  ]);
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
}

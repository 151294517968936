/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { AssumptionReport } from "../myModels/AssumptionReport";

export default function DataTable({ assumptionReport }: { assumptionReport: AssumptionReport }) {
  var renderData = () =>
  assumptionReport.BalanceByYears.map((r) => {
    var values = Object.values(r).map((v) => <td key={r.Age}>{v}</td>);
    return (
      <TableRow>
        {values}
      </TableRow>
    );
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell as="th">Year</TableCell>
          <TableCell as="th">Age</TableCell>
          <TableCell as="th">Begining Balance</TableCell>
          <TableCell as="th">Investment Growth</TableCell>
          <TableCell as="th">Savings</TableCell>
          <TableCell as="th">Super Annuation</TableCell>
          <TableCell as="th">Lump Sum Savings</TableCell>
          <TableCell as="th">Lump Sum Expenses</TableCell>
          <TableCell as="th">Withdrawals</TableCell>
          <TableCell as="th">End Balance</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderData()}
      </TableBody>
    </Table>
  );
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { API } from "aws-amplify";
import { getAssumption } from "../graphql/queries";
import { updateAssumption } from "../graphql/mutations";
export default function AssumptionUpdateForm(props) {
  const {
    id: idProp,
    assumption: assumptionModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    currentSavings: "",
    birthday: "",
    inflation: "",
    returnOnInvestiment: "",
    returnOnInvestimentAfterRetire: "",
    superAmount: "",
    superRecievingAge: "",
    monthlySaving: "",
    monthlyRetireExpense: "",
    retireAge: "",
    lifeExpectancy: "",
  };
  const [name, setName] = React.useState(initialValues.name);
  const [currentSavings, setCurrentSavings] = React.useState(
    initialValues.currentSavings
  );
  const [birthday, setBirthday] = React.useState(initialValues.birthday);
  const [inflation, setInflation] = React.useState(initialValues.inflation);
  const [returnOnInvestiment, setReturnOnInvestiment] = React.useState(
    initialValues.returnOnInvestiment
  );
  const [returnOnInvestimentAfterRetire, setReturnOnInvestimentAfterRetire] =
    React.useState(initialValues.returnOnInvestimentAfterRetire);
  const [superAmount, setSuperAmount] = React.useState(
    initialValues.superAmount
  );
  const [superRecievingAge, setSuperRecievingAge] = React.useState(
    initialValues.superRecievingAge
  );
  const [monthlySaving, setMonthlySaving] = React.useState(
    initialValues.monthlySaving
  );
  const [monthlyRetireExpense, setMonthlyRetireExpense] = React.useState(
    initialValues.monthlyRetireExpense
  );
  const [retireAge, setRetireAge] = React.useState(initialValues.retireAge);
  const [lifeExpectancy, setLifeExpectancy] = React.useState(
    initialValues.lifeExpectancy
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = assumptionRecord
      ? { ...initialValues, ...assumptionRecord }
      : initialValues;
    setName(cleanValues.name);
    setCurrentSavings(cleanValues.currentSavings);
    setBirthday(cleanValues.birthday);
    setInflation(cleanValues.inflation);
    setReturnOnInvestiment(cleanValues.returnOnInvestiment);
    setReturnOnInvestimentAfterRetire(
      cleanValues.returnOnInvestimentAfterRetire
    );
    setSuperAmount(cleanValues.superAmount);
    setSuperRecievingAge(cleanValues.superRecievingAge);
    setMonthlySaving(cleanValues.monthlySaving);
    setMonthlyRetireExpense(cleanValues.monthlyRetireExpense);
    setRetireAge(cleanValues.retireAge);
    setLifeExpectancy(cleanValues.lifeExpectancy);
    setErrors({});
  };
  const [assumptionRecord, setAssumptionRecord] =
    React.useState(assumptionModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await API.graphql({
              query: getAssumption.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getAssumption
        : assumptionModelProp;
      setAssumptionRecord(record);
    };
    queryData();
  }, [idProp, assumptionModelProp]);
  React.useEffect(resetStateValues, [assumptionRecord]);
  const validations = {
    name: [{ type: "Required" }],
    currentSavings: [{ type: "Required" }],
    birthday: [{ type: "Required" }],
    inflation: [{ type: "Required" }],
    returnOnInvestiment: [{ type: "Required" }],
    returnOnInvestimentAfterRetire: [],
    superAmount: [{ type: "Required" }],
    superRecievingAge: [{ type: "Required" }],
    monthlySaving: [{ type: "Required" }],
    monthlyRetireExpense: [{ type: "Required" }],
    retireAge: [{ type: "Required" }],
    lifeExpectancy: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          currentSavings,
          birthday,
          inflation,
          returnOnInvestiment,
          returnOnInvestimentAfterRetire:
            returnOnInvestimentAfterRetire ?? null,
          superAmount,
          superRecievingAge,
          monthlySaving,
          monthlyRetireExpense,
          retireAge,
          lifeExpectancy,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await API.graphql({
            query: updateAssumption.replaceAll("__typename", ""),
            variables: {
              input: {
                id: assumptionRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "AssumptionUpdateForm")}
      {...rest}
    >
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid0")}
      >
        <TextField
          label="Assumption Name"
          isRequired={true}
          isReadOnly={false}
          value={name}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                name: value,
                currentSavings,
                birthday,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.name ?? value;
            }
            if (errors.name?.hasError) {
              runValidationTasks("name", value);
            }
            setName(value);
          }}
          onBlur={() => runValidationTasks("name", name)}
          errorMessage={errors.name?.errorMessage}
          hasError={errors.name?.hasError}
          {...getOverrideProps(overrides, "name")}
        ></TextField>
        <TextField
          label="Current savings"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          value={currentSavings}
          onChange={(e) => {
            let value = isNaN(parseInt(e.target.value))
              ? e.target.value
              : parseInt(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings: value,
                birthday,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.currentSavings ?? value;
            }
            if (errors.currentSavings?.hasError) {
              runValidationTasks("currentSavings", value);
            }
            setCurrentSavings(value);
          }}
          onBlur={() => runValidationTasks("currentSavings", currentSavings)}
          errorMessage={errors.currentSavings?.errorMessage}
          hasError={errors.currentSavings?.hasError}
          {...getOverrideProps(overrides, "currentSavings")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid1")}
      >
        <TextField
          label="Birthday"
          isRequired={true}
          isReadOnly={false}
          type="date"
          value={birthday}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday: value,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.birthday ?? value;
            }
            if (errors.birthday?.hasError) {
              runValidationTasks("birthday", value);
            }
            setBirthday(value);
          }}
          onBlur={() => runValidationTasks("birthday", birthday)}
          errorMessage={errors.birthday?.errorMessage}
          hasError={errors.birthday?.hasError}
          {...getOverrideProps(overrides, "birthday")}
        ></TextField>
        <TextField
          label="Inflation"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          value={inflation}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday,
                inflation: value,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.inflation ?? value;
            }
            if (errors.inflation?.hasError) {
              runValidationTasks("inflation", value);
            }
            setInflation(value);
          }}
          onBlur={() => runValidationTasks("inflation", inflation)}
          errorMessage={errors.inflation?.errorMessage}
          hasError={errors.inflation?.hasError}
          {...getOverrideProps(overrides, "inflation")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid2")}
      >
        <TextField
          label="Return on investiment"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          value={returnOnInvestiment}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday,
                inflation,
                returnOnInvestiment: value,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.returnOnInvestiment ?? value;
            }
            if (errors.returnOnInvestiment?.hasError) {
              runValidationTasks("returnOnInvestiment", value);
            }
            setReturnOnInvestiment(value);
          }}
          onBlur={() =>
            runValidationTasks("returnOnInvestiment", returnOnInvestiment)
          }
          errorMessage={errors.returnOnInvestiment?.errorMessage}
          hasError={errors.returnOnInvestiment?.hasError}
          {...getOverrideProps(overrides, "returnOnInvestiment")}
        ></TextField>
        <TextField
          label="Return on investiment after retire"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={returnOnInvestimentAfterRetire}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire: value,
                superAmount,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.returnOnInvestimentAfterRetire ?? value;
            }
            if (errors.returnOnInvestimentAfterRetire?.hasError) {
              runValidationTasks("returnOnInvestimentAfterRetire", value);
            }
            setReturnOnInvestimentAfterRetire(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "returnOnInvestimentAfterRetire",
              returnOnInvestimentAfterRetire
            )
          }
          errorMessage={errors.returnOnInvestimentAfterRetire?.errorMessage}
          hasError={errors.returnOnInvestimentAfterRetire?.hasError}
          {...getOverrideProps(overrides, "returnOnInvestimentAfterRetire")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid3")}
      >
        <TextField
          label="Super amount"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          value={superAmount}
          onChange={(e) => {
            let value = isNaN(parseInt(e.target.value))
              ? e.target.value
              : parseInt(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount: value,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.superAmount ?? value;
            }
            if (errors.superAmount?.hasError) {
              runValidationTasks("superAmount", value);
            }
            setSuperAmount(value);
          }}
          onBlur={() => runValidationTasks("superAmount", superAmount)}
          errorMessage={errors.superAmount?.errorMessage}
          hasError={errors.superAmount?.hasError}
          {...getOverrideProps(overrides, "superAmount")}
        ></TextField>
        <TextField
          label="Super recieving age"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          value={superRecievingAge}
          onChange={(e) => {
            let value = isNaN(parseInt(e.target.value))
              ? e.target.value
              : parseInt(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge: value,
                monthlySaving,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.superRecievingAge ?? value;
            }
            if (errors.superRecievingAge?.hasError) {
              runValidationTasks("superRecievingAge", value);
            }
            setSuperRecievingAge(value);
          }}
          onBlur={() =>
            runValidationTasks("superRecievingAge", superRecievingAge)
          }
          errorMessage={errors.superRecievingAge?.errorMessage}
          hasError={errors.superRecievingAge?.hasError}
          {...getOverrideProps(overrides, "superRecievingAge")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <TextField
          label="Monthly saving"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          value={monthlySaving}
          onChange={(e) => {
            let value = isNaN(parseInt(e.target.value))
              ? e.target.value
              : parseInt(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge,
                monthlySaving: value,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.monthlySaving ?? value;
            }
            if (errors.monthlySaving?.hasError) {
              runValidationTasks("monthlySaving", value);
            }
            setMonthlySaving(value);
          }}
          onBlur={() => runValidationTasks("monthlySaving", monthlySaving)}
          errorMessage={errors.monthlySaving?.errorMessage}
          hasError={errors.monthlySaving?.hasError}
          {...getOverrideProps(overrides, "monthlySaving")}
        ></TextField>
        <TextField
          label="Monthly retire expense"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          value={monthlyRetireExpense}
          onChange={(e) => {
            let value = isNaN(parseInt(e.target.value))
              ? e.target.value
              : parseInt(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense: value,
                retireAge,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.monthlyRetireExpense ?? value;
            }
            if (errors.monthlyRetireExpense?.hasError) {
              runValidationTasks("monthlyRetireExpense", value);
            }
            setMonthlyRetireExpense(value);
          }}
          onBlur={() =>
            runValidationTasks("monthlyRetireExpense", monthlyRetireExpense)
          }
          errorMessage={errors.monthlyRetireExpense?.errorMessage}
          hasError={errors.monthlyRetireExpense?.hasError}
          {...getOverrideProps(overrides, "monthlyRetireExpense")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <TextField
          label="Retire age"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          value={retireAge}
          onChange={(e) => {
            let value = isNaN(parseInt(e.target.value))
              ? e.target.value
              : parseInt(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense,
                retireAge: value,
                lifeExpectancy,
              };
              const result = onChange(modelFields);
              value = result?.retireAge ?? value;
            }
            if (errors.retireAge?.hasError) {
              runValidationTasks("retireAge", value);
            }
            setRetireAge(value);
          }}
          onBlur={() => runValidationTasks("retireAge", retireAge)}
          errorMessage={errors.retireAge?.errorMessage}
          hasError={errors.retireAge?.hasError}
          {...getOverrideProps(overrides, "retireAge")}
        ></TextField>
        <TextField
          label="Life expectancy"
          isRequired={true}
          isReadOnly={false}
          type="number"
          step="any"
          value={lifeExpectancy}
          onChange={(e) => {
            let value = isNaN(parseInt(e.target.value))
              ? e.target.value
              : parseInt(e.target.value);
            if (onChange) {
              const modelFields = {
                name,
                currentSavings,
                birthday,
                inflation,
                returnOnInvestiment,
                returnOnInvestimentAfterRetire,
                superAmount,
                superRecievingAge,
                monthlySaving,
                monthlyRetireExpense,
                retireAge,
                lifeExpectancy: value,
              };
              const result = onChange(modelFields);
              value = result?.lifeExpectancy ?? value;
            }
            if (errors.lifeExpectancy?.hasError) {
              runValidationTasks("lifeExpectancy", value);
            }
            setLifeExpectancy(value);
          }}
          onBlur={() => runValidationTasks("lifeExpectancy", lifeExpectancy)}
          errorMessage={errors.lifeExpectancy?.errorMessage}
          hasError={errors.lifeExpectancy?.hasError}
          {...getOverrideProps(overrides, "lifeExpectancy")}
        ></TextField>
      </Grid>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || assumptionModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Update"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || assumptionModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import {
  Button,
  Heading,
  Link,
  Image,
  Flex,
  View,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
const myStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  color: "blue",
  padding: "10px",
  alignContent: "center",
};

const logoStyle: React.CSSProperties = {
  marginRight: "10px",
};
const linkStyle: React.CSSProperties = {
  alignContent: "center",
};

export default function NavBarHeader({
  signOut,
  user,
}: {
  signOut: () => void;
  user: any;
}) {
  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      alignContent="normal"
      wrap="nowrap"
      gap="10rem"
    >
      <View>
        <Flex direction="row" justifyContent="flex-start" alignItems="center">
          <View>
            <Link href={"/"}>
              <Image
                alt="Faith Retire"
                src="/logo-color.svg"
                height="3em"
                width="3em"
                style={logoStyle}
              ></Image>
            </Link>
          </View>

          <View>
            <Link textAlign={`center`} href={`/Dashboard`}>
              Dashboard
            </Link>
          </View>
          <View>
            <Link textAlign={`center`} href={`/Report`}>
              Report
            </Link>
          </View>
        </Flex>
      </View>
      <View>
        <Heading level={5}>
          Hello {user.attributes.email}{" "}
          <Button onClick={signOut}>Sign out</Button>
        </Heading>
      </View>
    </Flex>
  );
}

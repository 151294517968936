import { View, Image, Heading, Link } from "@aws-amplify/ui-react";

export const components = {
  Header() {
    return (
      <View textAlign="center">
        <Link href={"/"}>
          <Image
            alt="Faith Retire"
            src="/logo-color.svg"
            height="10em"
            width="10em"
          />
        </Link>
      </View>
    );
  },
  SignIn: {
    Header() {
      return <Heading level={3}>Sign in to your account</Heading>;
    },
  },
};

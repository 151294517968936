import { View, Image, Text, Button, Link } from "@aws-amplify/ui-react";
import NavBarHeader from "./NavBarHeader";
import MarketingFooterBrand from "./MarketingFooterBrand";

export default function LandingPage() {
  return (
    <View paddingLeft={"5em"} paddingRight={"5em"}>
      <View>
        <NavBarHeader></NavBarHeader>
      </View>
      <View>
        <center>
          <Text
            variation="primary"
            as="p"
            lineHeight="1.5em"
            fontWeight={700}
            fontSize="5em"
            fontStyle="normal"
            color={"blue"}
            textDecoration="none"
            width="60vw"
          >
            Plan Your Retirement With Faith
          </Text>

          <Text
            variation="primary"
            as="p"
            lineHeight="1.5em"
            fontWeight={700}
            fontSize="2em"
            color={"grey"}
            fontStyle="normal"
            textDecoration="none"
            width="60vw"
          >
            Secure Your Tomorrow with Our Retirement Planner Calculator
          </Text>
          <Link href={`Dashboard`}>
            <Button backgroundColor={"green"} color={"white"}>
              Start Planning Now
            </Button>
          </Link>
        </center>
      </View>
      <View id="Features">
        <center>
          <Text
            variation="primary"
            as="p"
            lineHeight="1.5em"
            fontWeight={400}
            fontSize="2em"
            width="60vw"
            marginTop={"3em"}
          >
            Save and Compare your scenarios easily
          </Text>
          <Image
            alt="Retirement Asset Projection"
            src="./RetirementAssetProjection.PNG"
          ></Image>
        </center>
        <center>
          <Text
            variation="primary"
            as="p"
            lineHeight="1.5em"
            fontWeight={400}
            fontSize="2em"
            width="60vw"
            marginTop={"3em"}
          >
            Analyse yearly saving with retire age
          </Text>
          <Image
            alt="Yearly Saving VS Retire Age"
            src="./YearlySavingVSAge.PNG"
          ></Image>
        </center>
      </View>
      <MarketingFooterBrand></MarketingFooterBrand>
    </View>
  );
}

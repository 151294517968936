import * as React from "react";
import { getOverrideProps } from "../components/utils";
import { Button, Flex, Text, Link } from "@aws-amplify/ui-react";
export default function MarketingFooterBrand(props) {
  const { overrides, ...rest } = props;
  return (
    <>
      <center>
        <Link href={`Dashboard`}>
          <Button backgroundColor={"green"} color={"white"}>
            Start Planning Now
          </Button>
        </Link>
      </center>
      <center id="ContactUs">
        <Text fontSize={"3em"}>Contact Us</Text>
        <Text fontSize={"2em"}>
          If you have any questions or concerns, please feel free to reach out
          to us via email: <br></br>
          <Link href="mailto:easyretirementplanner@gmail.com">
            easyretirementplanner@gmail.com
          </Link>
        </Text>
      </center>
      <center>
        <Text padding={"1em"} fontSize={"1.5em"}>
          @2023 Faith Retire Ltd. All rights reserved.
        </Text>
      </center>
    </>
  );
}

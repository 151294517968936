import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { Assumption, CreateAssumptionInput } from "../API";
import {
  createAssumption,
  deleteAssumption
} from "../graphql/mutations";
import { assumptionByEmail } from "../graphql/queries";
import { mapProperties } from "../utils";
import { DeleteIncomeStream } from "../components/IncomeStreamUpdateForm";
import { RefreshPage } from "./utils";

export async function GetAssumptionsByEmail(email: string) {
  const result = await API.graphql({
    query: assumptionByEmail,
    variables: { email: email },
  });
  const assumptions = "data" in result ? result.data.assumptionByEmail.items : undefined;
  console.log(assumptions);
  return assumptions;
}

export async function DeleteAssumption(assumption: Assumption) {
  if (assumption.incomeStreams?.items) {
    await Promise.all(
      assumption.incomeStreams?.items.map(async (incomeStream) => {
        if (incomeStream?.id) {
          return await DeleteIncomeStream(incomeStream?.id);
        }
      })
    );
  }
  await API.graphql({
    query: deleteAssumption,
    variables: {
      input: {
        id: assumption.id,
      },
    },
  });
  RefreshPage();
}

export async function DuplicateAssumption(assumption: Assumption) {
  var createAssumptionInput: CreateAssumptionInput = {
    id: "",
    name: "",
    birthday: "",
    email: "",
    inflation: 0,
    returnOnInvestiment: 0,
    returnOnInvestimentAfterRetire: 0,
    superAmount: 0,
    superRecievingAge: 0,
    lifeExpectancy: 0,
    monthlySaving: 0,
    retireAge: 0,
    monthlyRetireExpense: 0,
    currentSavings: 0,
  };

  mapProperties(assumption, createAssumptionInput);
  createAssumptionInput.id = uuidv4();
  try {
    await API.graphql({
      query: createAssumption,
      variables: {
        input: createAssumptionInput,
      },
    });
  } catch (error) {
    console.log(error);
  }
  RefreshPage();
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import {
  Button,
  Heading,
  Link,
  Image,
  Flex,
  View,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
const myStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  color: "blue",
  padding: "10px",
  alignContent: "center",
};

const logoStyle: React.CSSProperties = {
  marginRight: "10px",
};
const linkStyle: React.CSSProperties = {
  alignContent: "center",
};

export default function NavBarHeader() {
  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      alignContent="normal"
      wrap="nowrap"
      gap="10rem"
    >
      <View>
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <View >
            <Image
              alt="Faith Retire"
              src="/logo-color.svg"
              height="3em"
              width="3em"
              style={logoStyle}
            ></Image>
          </View>

          <View>
            <Link textAlign={`center`} href={`#Features`}>
              Features
            </Link>
          </View>
          <View>
            <Link textAlign={`center`} href={`#ContactUs`}>
              Contact Us
            </Link>
          </View>
        </Flex>
      </View>
      <View>
        <Heading level={5}>
            <Link textAlign={`center`} href={`/Dashboard`}>
              Sign In
            </Link>
        </Heading>
      </View>
    </Flex>
  );
}

import {
  Alert,
  Button,
  Expander,
  ExpanderItem,
  Grid,
  SelectField,
  View,
  useTheme,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Assumption, ExpenseStream, IncomeStream } from "../API";
import { BarChart } from "../components/BarChart";
import IncomeStreamCreateForm from "../components/IncomeStreamCreateForm";
import IncomeStreamUpdateForm from "../components/IncomeStreamUpdateForm";
import ExpenseStreamCreateForm from "../components/ExpenseStreamCreateForm";
import ExpenseStreamUpdateForm from "../components/ExpenseStreamUpdateForm";
import LineChart from "../components/LineChart";
import NavBarHeader from "../components/NavBarHeader";
import { components } from "../components/SignInComponents";
import { assumptionByEmail } from "../graphql/queries";
import {
  DeleteAssumption,
  DuplicateAssumption
} from "../lib/AssumptionLib";
import { RefreshPage } from "../lib/utils";
import AssumptionCreateForm from "../ui-components/AssumptionCreateForm";
import AssumptionUpdateForm from "../ui-components/AssumptionUpdateForm";

function Dashboard({
  signOut,
  user,
}: {
  signOut: any;
  user: any;
}) {
  async function GetAssumptionsByEmail(email: string) {
    const result = await API.graphql({
      query: assumptionByEmail,
      variables: { email: email },
    });
    const assumptions =
      "data" in result ? result.data.assumptionByEmail.items : undefined;
    setAssumptions(assumptions);
  }
  const { tokens } = useTheme();
  const [assumptions, setAssumptions] = useState<Assumption[]>();
  const [updateAssumption, setUpdateAssumption] = useState<Assumption>();
  const [alertMessage, setAlertMessage] = useState("");
  const [incomeStreams, setIncomeStreams] = useState<
    (IncomeStream | null)[] | undefined
  >([]);
  const [expenseStreams, setExpenseStreams] = useState<
    (ExpenseStream | null)[] | undefined
  >([]);

  useEffect(() => {
    GetAssumptionsByEmail(user.attributes.email);
    return () => {};
  }, [user]);

  if (!assumptions || assumptions.length == 0) {
    return (
      <div>
        <NavBarHeader signOut={signOut} user={user}></NavBarHeader>
        {alertMessage && (
          <Alert variation="error" heading="Error" isDismissible={true}>
            {" "}
            {alertMessage}
          </Alert>
        )}
        <AssumptionCreateForm
          onSubmit={(fields) => {
            fields.email = user.attributes.email;
            fields.id = uuidv4();
            return fields;
          }}
          onSuccess={(fields) => RefreshPage()}
        ></AssumptionCreateForm>
      </div>
    );
  } else {
    return (
      <>
        <NavBarHeader signOut={signOut} user={user}></NavBarHeader>
        {alertMessage && (
          <Alert variation="error" heading="Error" isDismissible={true}>
            {" "}
            {alertMessage}
          </Alert>
        )}
        <Grid
          templateColumns="1fr 1fr 1fr"
          templateRows="40rem"
          gap={tokens.space.small}
        >
          <View columnSpan={1}>
            <Expander
              type="single"
              isCollapsible={true}
              defaultValue={"udpate"}
            >
              <ExpanderItem title="Update Assumptions" value="udpate">
                <SelectField
                  label=""
                  placeholder="Choose your assumption"
                  onChange={(e) => {
                    const assumption = assumptions.find(
                      (item) => item.id === e.target.value
                    );
                    setUpdateAssumption(assumption);
                    assumption?.incomeStreams?.items &&
                      setIncomeStreams(assumption!.incomeStreams?.items);
                    
                    assumption?.expenseStreams?.items &&
                      setExpenseStreams(assumption!.expenseStreams?.items);
                  }}
                >
                  {assumptions?.map((assumption) => (
                    <option value={assumption.id} key={assumption.id}>
                      {assumption.name ?? ""}
                    </option>
                  ))}
                </SelectField>
                {updateAssumption && (
                  <>
                    <AssumptionUpdateForm
                      assumption={updateAssumption}
                      key={updateAssumption.id}
                      onSuccess={() => {
                        RefreshPage();
                      }}
                    ></AssumptionUpdateForm>
                    {incomeStreams &&
                      incomeStreams.map(
                        (incomeStream) =>
                          incomeStream && (
                            <IncomeStreamUpdateForm
                              key={incomeStream.id}
                              incomeStream={incomeStream}
                              assumptionId={updateAssumption.id}
                            ></IncomeStreamUpdateForm>
                          )
                      )}
                    <IncomeStreamCreateForm
                      assumption={updateAssumption}
                    ></IncomeStreamCreateForm>

                    
                    {expenseStreams &&
                      expenseStreams.map(
                        (expenseStream) =>
                        expenseStream && (
                            <ExpenseStreamUpdateForm
                              key={expenseStream.id}
                              expenseStream={expenseStream}
                              assumptionId={updateAssumption.id}
                            ></ExpenseStreamUpdateForm>
                          )
                      )}
                    <ExpenseStreamCreateForm
                      assumption={updateAssumption}
                    ></ExpenseStreamCreateForm>

                    <Button
                      children="Delete"
                      variation="destructive"
                      onClick={() => DeleteAssumption(updateAssumption)}
                    ></Button>
                    <Button
                      children="Duplicate"
                      variation="primary"
                      onClick={() => DuplicateAssumption(updateAssumption)}
                    ></Button>
                  </>
                )}
              </ExpanderItem>
              <ExpanderItem title="Create Assumption" value="demo-item-2">
                <div>
                  <AssumptionCreateForm
                    onSubmit={(fields) => {
                      fields.email = user.email;
                      fields.id = uuidv4();
                      return fields;
                    }}
                    onSuccess={(fields) => RefreshPage()}
                  ></AssumptionCreateForm>
                </div>
              </ExpanderItem>
            </Expander>
          </View>
          <View columnSpan={2}>
            <div id="chart">
              {assumptions && (
                <LineChart assumptions={assumptions} onError={(message:string)=>setAlertMessage(message)} />
              )}
            </div>
            <div id="barChart">
              {assumptions && (
                <BarChart
                  assumptions={assumptions}
                  onError={(message:string)=>setAlertMessage(message)}
                ></BarChart>
              )}
            </div>
          </View>
        </Grid>
      </>
    );
  }
}

export default withAuthenticator(Dashboard, { components: components });

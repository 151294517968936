import { Assumption } from "../API";
import { ERROR_ID } from "../constants";
import { API } from "aws-amplify";
import {
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Retirement Asset Projection",
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "Age",
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Dollar",
      },
    },
  },
};

export default function LineChart({
  assumptions,
  onError,
}: {
  assumptions: Assumption[];
  onError: any;
}) {
  const [lineChartData, setLineChartData] = useState<any>({
    labels: [1, 2, 3],
    datasets: [
      {
        label: "Assumption Name 1",
        data: [100, 200, 300],
      },
    ],
  });
  useEffect(() => {
    CalculateLineChart(assumptions);
    return () => {
      // You can perform cleanup here if needed.
      // For example, cancelling network requests, clearing intervals, etc.
    };
  }, [assumptions]);
  return <Line options={options} data={lineChartData} />;

  async function CalculateLineChart(assumptions: Assumption[]) {
    const apiName = "FaithRetireRestAPI";
    const path = "/chart/line";
    const myInit = {
      body: assumptions,
    };
    try {
      const lineChartData = await API.post(apiName, path, myInit);
      setLineChartData(lineChartData);
    } catch (error) {
      if (error instanceof Error) {
        onError(error.message);
      } else {
        onError(ERROR_ID.UNABLE_TO_CALCULATE);
      }
    }
  }
}

export enum ERROR_ID {
  INVALID_PERMISSIONS = "InvalidPermissions",
  NO_FEATURE_ACCESS = "NoFeatureAccess",
  ORG_OFFLINE = "OrgOffline",
  FAILED_AUTHENTICATION = "FailedAuthentication",
  NOT_FOUND = "NotFound",
  UNABLE_TO_RETRIEVE = "UnableToRetrieve",
  UNABLE_TO_CALCULATE = "Faile to calculate the chart, the assumption isn't correct",
  UNABLE_TO_TRANSFER = "UnableToTransfer",
}

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import { RefreshPage } from "../lib/utils";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import * as React from "react";
import { Assumption } from "../API";
import { createExpenseStream } from "../graphql/mutations";
export default function ExpenseStreamCreateForm(props: {
  assumption: Assumption;
}) {
  const { assumption } = props;
  const initialValues = {
    name: "Expense Stream",
    amount: 50000,
    startAge: 50,
    endAge: 50,
  };
  const [name, setName] = React.useState(initialValues.name);
  const [amount, setAmount] = React.useState(initialValues.amount);
  const [startAge, setStartAge] = React.useState(initialValues.startAge);
  const [endAge, setEndAge] = React.useState(initialValues.endAge);
  const [errors, setErrors] = React.useState<any>({});
  const resetStateValues = () => {
    setName(initialValues.name);
    setAmount(initialValues.amount);
    setStartAge(initialValues.startAge);
    setEndAge(initialValues.endAge);
    setErrors({});
  };
  const validations = {
    name: [{ type: "Required" }],
    amount: [{ type: "Required" }],
    startAge: [{ type: "Required" }],
    endAge: [{ type: "Required" }],
  };
  return (
      <Grid
        as="form"
        rowGap="15px"
        columnGap="15px"
        padding="20px"
        border={`1px black`}
        onSubmit={async (event) => {
          event.preventDefault();
          let modelFields = {
            name,
            amount,
            startAge,
            endAge,
          };
          try {
            await API.graphql({
              query: createExpenseStream.replaceAll("__typename", ""),
              variables: {
                input: {
                  assumptionId: assumption.id,
                  ...modelFields,
                },
              },
            });

            RefreshPage();
          } catch (err) {
            if (err instanceof Error) {
              setErrors({ message: err.message });
            }
          }
        }}
      >
        <h3>Add Expense Stream</h3>
        <Grid
          columnGap="inherit"
          rowGap="inherit"
          templateColumns="repeat(2, auto)"
        >
          <TextField
            label="ExpenseStream Name"
            isRequired={true}
            isReadOnly={false}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          ></TextField>
          <TextField
            label="Amount"
            isRequired={true}
            isReadOnly={false}
            type="number"
            step="any"
            value={amount}
            onChange={(e) => {
              if (isNaN(parseInt(e.target.value))) {
                setErrors({
                  ...errors,
                  amount: {
                    errorMessage: "not a number",
                    hasError: true,
                  },
                });
              } else {
                setAmount(parseInt(e.target.value));
              }
            }}
            errorMessage={errors?.amount?.errorMessage}
            hasError={errors.amount?.hasError}
          ></TextField>
        </Grid>
        <Grid
          columnGap="inherit"
          rowGap="inherit"
          templateColumns="repeat(2, auto)"
        >
          <TextField
            label="Start Age"
            isRequired={true}
            isReadOnly={false}
            type="number"
            step="any"
            value={startAge}
            onChange={(e) => {
              if (isNaN(parseInt(e.target.value))) {
                setErrors({
                  ...errors,
                  startAge: {
                    errorMessage: "not a number",
                    hasError: true,
                  },
                });
              } else {
                setStartAge(parseInt(e.target.value));
              }
            }}
            errorMessage={errors?.startAge?.errorMessage}
            hasError={errors.startAge?.hasError}
          ></TextField>
          <TextField
            label="End Age"
            isRequired={true}
            isReadOnly={false}
            type="number"
            step="any"
            value={endAge}
            onChange={(e) => {
              if (isNaN(parseInt(e.target.value))) {
                setErrors({
                  ...errors,
                  endAge: {
                    errorMessage: "not a number",
                    hasError: true,
                  },
                });
              } else {
                setEndAge(parseInt(e.target.value));
              }
            }}
            errorMessage={errors?.endAge?.errorMessage}
            hasError={errors.endAge?.hasError}
          ></TextField>
        </Grid>
        <Flex justifyContent="space-between">
          <Button
            children="Reset"
            type="reset"
            onClick={(event) => {
              event.preventDefault();
              resetStateValues();
            }}
          ></Button>
          <Flex gap="15px">
            <Button
              children="Add"
              type="submit"
              variation="primary"
              isDisabled={Object.values(errors).some((e: any) => e?.hasError)}
            ></Button>
          </Flex>
        </Flex>
      </Grid>
  );
}

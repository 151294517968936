/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createAssumption = /* GraphQL */ `mutation CreateAssumption(
  $input: CreateAssumptionInput!
  $condition: ModelAssumptionConditionInput
) {
  createAssumption(input: $input, condition: $condition) {
    id
    name
    birthday
    email
    inflation
    returnOnInvestiment
    returnOnInvestimentAfterRetire
    superAmount
    superRecievingAge
    monthlySaving
    retireAge
    monthlyRetireExpense
    currentSavings
    lifeExpectancy
    incomeStreams {
      nextToken
      __typename
    }
    expenseStreams {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssumptionMutationVariables,
  APITypes.CreateAssumptionMutation
>;
export const updateAssumption = /* GraphQL */ `mutation UpdateAssumption(
  $input: UpdateAssumptionInput!
  $condition: ModelAssumptionConditionInput
) {
  updateAssumption(input: $input, condition: $condition) {
    id
    name
    birthday
    email
    inflation
    returnOnInvestiment
    returnOnInvestimentAfterRetire
    superAmount
    superRecievingAge
    monthlySaving
    retireAge
    monthlyRetireExpense
    currentSavings
    lifeExpectancy
    incomeStreams {
      nextToken
      __typename
    }
    expenseStreams {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssumptionMutationVariables,
  APITypes.UpdateAssumptionMutation
>;
export const deleteAssumption = /* GraphQL */ `mutation DeleteAssumption(
  $input: DeleteAssumptionInput!
  $condition: ModelAssumptionConditionInput
) {
  deleteAssumption(input: $input, condition: $condition) {
    id
    name
    birthday
    email
    inflation
    returnOnInvestiment
    returnOnInvestimentAfterRetire
    superAmount
    superRecievingAge
    monthlySaving
    retireAge
    monthlyRetireExpense
    currentSavings
    lifeExpectancy
    incomeStreams {
      nextToken
      __typename
    }
    expenseStreams {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAssumptionMutationVariables,
  APITypes.DeleteAssumptionMutation
>;
export const createIncomeStream = /* GraphQL */ `mutation CreateIncomeStream(
  $input: CreateIncomeStreamInput!
  $condition: ModelIncomeStreamConditionInput
) {
  createIncomeStream(input: $input, condition: $condition) {
    id
    name
    amount
    startAge
    endAge
    assumptionId
    assumption {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIncomeStreamMutationVariables,
  APITypes.CreateIncomeStreamMutation
>;
export const updateIncomeStream = /* GraphQL */ `mutation UpdateIncomeStream(
  $input: UpdateIncomeStreamInput!
  $condition: ModelIncomeStreamConditionInput
) {
  updateIncomeStream(input: $input, condition: $condition) {
    id
    name
    amount
    startAge
    endAge
    assumptionId
    assumption {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIncomeStreamMutationVariables,
  APITypes.UpdateIncomeStreamMutation
>;
export const deleteIncomeStream = /* GraphQL */ `mutation DeleteIncomeStream(
  $input: DeleteIncomeStreamInput!
  $condition: ModelIncomeStreamConditionInput
) {
  deleteIncomeStream(input: $input, condition: $condition) {
    id
    name
    amount
    startAge
    endAge
    assumptionId
    assumption {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIncomeStreamMutationVariables,
  APITypes.DeleteIncomeStreamMutation
>;
export const createExpenseStream = /* GraphQL */ `mutation CreateExpenseStream(
  $input: CreateExpenseStreamInput!
  $condition: ModelExpenseStreamConditionInput
) {
  createExpenseStream(input: $input, condition: $condition) {
    id
    name
    amount
    startAge
    endAge
    assumptionId
    assumption {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExpenseStreamMutationVariables,
  APITypes.CreateExpenseStreamMutation
>;
export const updateExpenseStream = /* GraphQL */ `mutation UpdateExpenseStream(
  $input: UpdateExpenseStreamInput!
  $condition: ModelExpenseStreamConditionInput
) {
  updateExpenseStream(input: $input, condition: $condition) {
    id
    name
    amount
    startAge
    endAge
    assumptionId
    assumption {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExpenseStreamMutationVariables,
  APITypes.UpdateExpenseStreamMutation
>;
export const deleteExpenseStream = /* GraphQL */ `mutation DeleteExpenseStream(
  $input: DeleteExpenseStreamInput!
  $condition: ModelExpenseStreamConditionInput
) {
  deleteExpenseStream(input: $input, condition: $condition) {
    id
    name
    amount
    startAge
    endAge
    assumptionId
    assumption {
      id
      name
      birthday
      email
      inflation
      returnOnInvestiment
      returnOnInvestimentAfterRetire
      superAmount
      superRecievingAge
      monthlySaving
      retireAge
      monthlyRetireExpense
      currentSavings
      lifeExpectancy
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExpenseStreamMutationVariables,
  APITypes.DeleteExpenseStreamMutation
>;
export const createUserInfo = /* GraphQL */ `mutation CreateUserInfo(
  $input: CreateUserInfoInput!
  $condition: ModelUserInfoConditionInput
) {
  createUserInfo(input: $input, condition: $condition) {
    id
    email
    firstName
    lastName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserInfoMutationVariables,
  APITypes.CreateUserInfoMutation
>;
export const updateUserInfo = /* GraphQL */ `mutation UpdateUserInfo(
  $input: UpdateUserInfoInput!
  $condition: ModelUserInfoConditionInput
) {
  updateUserInfo(input: $input, condition: $condition) {
    id
    email
    firstName
    lastName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserInfoMutationVariables,
  APITypes.UpdateUserInfoMutation
>;
export const deleteUserInfo = /* GraphQL */ `mutation DeleteUserInfo(
  $input: DeleteUserInfoInput!
  $condition: ModelUserInfoConditionInput
) {
  deleteUserInfo(input: $input, condition: $condition) {
    id
    email
    firstName
    lastName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserInfoMutationVariables,
  APITypes.DeleteUserInfoMutation
>;
